<template>
<v-navigation-drawer ref="rightContainer" class="summary-editor1" fixed right temporary width="720" v-model="summaryShow" overlay-color="white" overlay-opacity="0" color="white">
  <Header :type="summaryType" style="position:fixed;right:0;width:100%" @addItemLabel="on_addItemLabel" @remove="deleteReport" @edit="editReport" @scroll="scroll" @closeRightSummaryEdit="handle_closeRightSummaryEdit" />
  <v-divider id="at"></v-divider>
  <div class="pt-12" style="heigth:400px;overflow:scroll;">
    <Title :info="reportInfo" />
    <v-container class="pa-4">
      <Content :info="reportInfo" :type="summaryType" />
      <div id="clip">
        <!-- file upload -->
        <v-container v-if="showFiles">
          <div class="d-flex">
            <div>上传附件</div>
            <div style="cursor:pointer;" class="blue--text ml-2" @click="openFiles">点击上传</div>
            <input ref="fileUpload" style="display:none;" type="file" @change="onFileChanged">
            <v-spacer></v-spacer>
          </div>
        </v-container>
        <!-- file upload end -->
        <div class="d-flex" style="width:650px;overflow:auto;" v-if="showFiles">
          <v-card color="#B0C4DE" width="230" class="py-2 my-1 mx-1" outlined v-for="(file, i) in reportInfo.rp_files" :key="i">
            <div class="d-flex px-3">
              <div class="blue--text">
                {{ file.file_name }}
              </div>
              <v-spacer></v-spacer>
              <a style="text-decoration:none;" :href="`${url}/${file.file_id}`">
                <v-icon>mdi-download</v-icon>
              </a>
              <v-icon @click="removeFile(file.id)">mdi-close</v-icon>
            </div>
          </v-card>
          <v-spacer></v-spacer>
        </div>
      </div>
      <v-divider class="mt_25"></v-divider>
      <Bottom />
      <!-- <Operating :info="reportInfo" :type="summaryType" /> -->
      <!-- <Intercom :info="reportInfo" :type="summaryType" /> -->
      <Operating v-if="this.reportInfo" :op_obj="{op_parent: this.reportInfo.rp_id,op_parent_type: 4}" />
      <LikingComponent ref="likingComp" v-if="this.reportInfo" :rp_id="this.reportInfo.rp_id" rp_type="4" />
    </v-container>
  </div>
  <DailyReportModal ref="dailyReport" />
  <WeeklyReportModal ref="weeklyReport" />
  <MonthlyReportModal ref="monthlyReport" />
</v-navigation-drawer>
</template>

<script>
import Header from '@/components/summary/rightSummaryEdit/header/Header.vue';
import Title from '@/components/summary/rightSummaryEdit/title/Title.vue';
import Content from '@/components/summary/rightSummaryEdit/content/Content.vue';
import Bottom from '@/components/summary/rightSummaryEdit/bottom/Bottom.vue';
// import Operating from '@/components/summary/rightSummaryEdit/bottom/Operating.vue';
// import Intercom from '@/components/summary/rightSummaryEdit/bottom/Intercom.vue';
import {
  mapActions,
  mapGetters
} from 'vuex';
import * as endpoint from "@/config/endpoints";
export default {
  data() {
    return {
      summaryShow: false,
      summaryType: null,
      reportInfo: null,
      week: null,
      files: [],
      showFiles: null,
      url: endpoint.FILE_DOWNLOAD
    }
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
  },
  components: {
    Header,
    Title,
    Content,
    Bottom,
    Operating: () => import('@/components/common/operating/Operating.vue'),
    // Intercom,
    LikingComponent: () => import('@/components/summary/LikingComponent.vue'),
    DailyReportModal: () => import('@/components/summary/DailyReportModal.vue'),
    WeeklyReportModal: () => import('@/components/summary/WeeklyReportModal.vue'),
    MonthlyReportModal: () => import('@/components/summary/MonthlyReportModal.vue'),
  },
  methods: {
    ...mapActions('report', ['removeReport', 'updateDailyReport', 'updateWeeklyReport', 'updateMonthlyReport', 'getAllDailyReportWithCreator', 'getAllWeeklyReportWithCreator', 'getAllMonthlyReportWithCreator']),
    ...mapActions('fileUpload', ['filesUpload', 'deleteFileUploadById']),
    on_addItemLabel(e) {
      this.$refs.likingComp.addItemLabel(e);
    },
    displayRightSummary(prop) {
      this.reportInfo = prop.value;
      this.summaryType = prop.type;
      this.summaryShow = true;
      this.week = prop.week;
      this.showFiles = false;
    },
    handle_closeRightSummaryEdit() {
      this.summaryShow = false;
    },
    editReport() {
      if (this.reportInfo.rp_type == 0) {
        const date = new Date(this.reportInfo.rp_date);
        const rpDate = {
          year: date.getFullYear(),
          month: date.getMonth(),
          date: date.getDate() + 1
        }
        this.$refs.dailyReport.displayModal({
          edit: true,
          rp: this.reportInfo,
          rp_date: rpDate,
        })
      } else if (this.reportInfo.rp_type == 1) {
        const week = {
          year: this.reportInfo.rp_date.split('-')[0],
          month: this.reportInfo.rp_date.split('-')[1].split('/')[0],
          week: this.reportInfo.rp_date.split('-')[1].split('/')[1]
        }
        this.$refs.weeklyReport.displayWeeklyReportModal({
          edit: true,
          rp: this.reportInfo,
          rp_date: week,
          rp_range: this.week,
        });
      } else if (this.reportInfo.rp_type == 2) {
        const month = {
          year: this.reportInfo.rp_date.split('-')[0],
          month: this.reportInfo.rp_date.split('-')[1],
        };
        this.$refs.monthlyReport.displayMonthlyReportModal({
          edit: true,
          rp: this.reportInfo,
          rp_date: month
        });
      }
    },
    scroll(e) {
      document.getElementById(e).scrollIntoView({
        behavior: "smooth"
      })
      if (e == 'clip') {
        this.showFiles = true;
      }
    },
    deleteReport() {
      this.removeReport({
        rp_id: this.reportInfo.rp_id,
        rp_type: this.reportInfo.rp_type,
      });
      this.handle_closeRightSummaryEdit();
    },
    //file upload
    openFiles() {
      this.$refs.fileUpload.click();
    },
    removeFile(id) {
      let files = !this.reportInfo.rp_attatch_files ? [] : this.reportInfo.rp_attatch_files.split(',').filter(f => f != '');
      const index = files.findIndex(f => f == id);
      files.splice(index, 1);
      this.updateReport(files);
      this.deleteFileUploadById({file_id: id});
    },
    updateReport(files) {
      if (this.summaryType == 'daily') {
        this.updateDailyReport({
          rp_id: this.reportInfo.rp_id,
          rp_attatch_files: files.join()
        }).then(() => {
          this.getAllDailyReportWithCreator({
            rp_creator: this.authUser.id,
            rp_type: 0
          }).then(res => {
            const index = res.findIndex(report => report.rp_id == this.reportInfo.rp_id);
            this.reportInfo = res[index];
          })
        })
      } else if (this.summaryType == 'weekly') {
        this.updateWeeklyReport({
          rp_id: this.reportInfo.rp_id,
          rp_attatch_files: files.join()
        }).then(() => {
          this.getAllWeeklyReportWithCreator({
            rp_creator: this.authUser.id,
            rp_type: 1
          }).then(res => {
            const index = res.findIndex(report => report.rp_id == this.reportInfo.rp_id);
            this.reportInfo = res[index];
          })
        })
      } else if (this.summaryType == 'monthly') {
        this.updateMonthlyReport({
          rp_id: this.reportInfo.rp_id,
          rp_attatch_files: files.join()
        }).then(() => {
          this.getAllMonthlyReportWithCreator({
            rp_creator: this.authUser.id,
            rp_type: 2
          }).then(res => {
            const index = res.findIndex(report => report.rp_id == this.reportInfo.rp_id);
            this.reportInfo = res[index];
          })
        })
      }
    },
    onFileChanged(e) {
      this.files.push(e.target.files[0]);
      let formData = new FormData();
      formData.append('files', e.target.files[0]);
      this.filesUpload(formData).then(res => {
        let files = !this.reportInfo.rp_attatch_files ? [] : this.reportInfo.rp_attatch_files.split(',').filter(f => f != '');
        files.push(res[0].file_id);
        this.updateReport(files);
      })
    }
    // file upload end
  }
}
</script>

<style>
.mt_25 {
  margin-top: 25px;
}

.summary-editor {
  height: calc(100% - 40px) !important;
  z-index: 12;
  position: fixed;
  top: 40px !important;
  right: 0px;
}
</style>
